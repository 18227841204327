import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

export default class NpsController extends Controller {
    static defaultOptions = { responsive: true, maintainAspectRatio: false }
    static targets = [
        'avaliacoesPositivas',
        'avaliacoesNegativas',
        'avaliacoesNeutras',
        'blueBox',
        'grayBox'
    ]

    initialize() {
        this.jsonData = this.getJSONFromDataAttribute(this.element, 'chart-data')
        this.defaultSector = 'TOTAL'
        this.currentData = {}
        this.setCurrentData(this.defaultSector)
        this.barData1 = this.jsonData
        this.percentageData = { ...this.jsonData }
        delete this.percentageData.TOTAL
        console.log(this.jsonData)
        console.log(this.percentageLabels())
    }

    connect() {
        this.render();
    }

    render() {
        const barChart1 = document.getElementById('horizontal-bar-chart-1')
        if (barChart1) { this.createBarChart(barChart1, 'positive') }

        const barChart2 = document.getElementById('horizontal-bar-chart-2')
        if (barChart2) { this.createBarChart(barChart2, 'negative') }

        const lineChart1 = document.getElementById('line-chart-1')
        if (lineChart1) { this.createLineChart(lineChart1) }

        this.setAvaliacoes()
        this.setGrayBoxes()
        this.setBlueBox()
    }

    jsonData() {
        return this.jsonData
    }

    percentageLabels() {
        return Object.values(this.percentageData).map((pct) => pct.Motivos)
    }

    positivePercentages() {
        return Object.values(this.percentageData).map((pct) => this.toFloat(pct.porcentagem_promotores))
    }

    negativePercentages() {
        return Object.values(this.percentageData).map((pct) => this.toFloat(pct.porcentagem_detratores))
    }

    toFloat(string) {
        return parseFloat(string.replace('%', ''))
    }

    availableSectors() {
        return Object.keys(this.jsonData())
    }

    setCurrentData(key) {
        this.currentData = this.jsonData[key]
    }

    setAvaliacoes() {
        this.avaliacoesPositivasTarget.textContent = this.currentData.avaliacoes_positivas
        this.avaliacoesNegativasTarget.textContent = this.currentData.avaliacoes_negativas
        this.avaliacoesNeutrasTarget.textContent = this.currentData.avaliacoes_neutras
    }

    setGrayBoxes() {
        const values = {
            "Disparos": this.currentData.qtd_disparos,
            "Opiniões": this.currentData.qtd_respostas,
            "Taxa de Resposta": this.currentData.taxa_de_resposta.replace('%', '')
        }

        this.grayBoxTargets.forEach((box, i) => {
            let titleTag = box.querySelector('h4')
            let valueTag = box.querySelector('.number-value')
            let symbolTag = box.querySelector('.symbol')
            let valuesArray = Object.entries(values)

            titleTag.textContent = valuesArray[i][0] || 'N/A'
            valueTag.value = valuesArray[i][1] || 'N/A'
            if (valuesArray[i][0] == 'Taxa de Resposta') { symbolTag.textContent = '%' }
        })
    }

    setBlueBox() {
        let titleTag = this.blueBoxTarget.querySelector('h4')
        let valueTag = this.blueBoxTarget.querySelector('h2')

        titleTag.textContent = 'NPS' || 'N/A'
        valueTag.textContent = this.currentData.NPS || 'N/A'
    }

    getValuesFromData(key) {
        let data = []
        try {
            data = Object.values(this.currentData[key])
        } catch (error) { console.log(`no data available for ${key}`) }
        return data
    }

    createLineChart(element) {
        const data1 = this.getValuesFromData('evolucao_NPS')

        new Chart(element, {
            type: 'line',
            data: {
                labels: Object.keys(this.currentData.evolucao_NPS),
                datasets: [
                    {
                        label: 'NPS',
                        data: data1,
                        borderWidth: 3,
                        borderColor: '#0E5FFA',
                        pointRadius: 1
                    }
                ]
            },
            options: {
                plugins: {
                    datalabels: {
                        align: 'top',
                        display: true, // Always display the labels
                        formatter: (value) => value, // Show the value
                        color: '#000' // Label color
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            stepSize: 1
                        }
                    }
                }
            }
        });
    }

    createBarChart(element, metric) {

        const color = metric == 'positive' ? '#0E5FFA' : '#F43f5E'
        const data = metric == 'positive' ? this.positivePercentages() : this.negativePercentages()

        new Chart(element, {
            type: 'bar',
            data: {
                labels: this.percentageLabels(),
                datasets: [{
                    label: '%',
                    data: data,
                    borderWidth: 1,
                    borderRadius: 2,
                    backgroundColor: color
                }]
            },
            options: {
                plugins: {
                    datalabels: {
                        display: true,
                        align: 'center',  // Center label inside the bar
                        anchor: 'center', // Center anchor point
                        formatter: (value) => value, // Prefix value with a dollar sign
                        color: '#000' // White text for labels
                    }
                },
                animation: {
                    duration: 2000
                },
                indexAxis: 'y',
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            align: 'start'
                        }
                    },
                    x: {
                        reverse: true
                    }
                }
            }
        });
    }

    getJSONFromDataAttribute(element, attribute) {
        return JSON.parse(element.getAttribute(`data-${attribute}`))
    }
}
